<template>
	<div class="kounei">
		<el-row>
			<el-col :span="12" style="text-align: center;">
				<h4>上传口内</h4>
				<div align="left" style="padding-left: 6rem;font-size: 0.875rem;">请在此处上传口内文件。请注意文件格式。</div>
				<div align="left" style="padding-left: 6rem;font-size: 0.875rem;">您可以在<a href="#">此处</a>找到有关图片的示例。(点击后跳转出示例文件页)</div>
				<div align="left" style="padding-left: 6rem;font-size: 0.875rem;">重要提示：要创建一个治疗方案，需要上、下颌的3D扫描文件及咬合记录。</div>
				
				<div style="width: 70%;margin: 0px auto;" >
					<el-form :model="ruleForm"  ref="ruleForm">
						<el-form-item prop="chengxiangDate" label="成像日期">
						  <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.chengxiangDate" style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy/MM/dd"></el-date-picker>		     
						</el-form-item>
					</el-form>
					
				</div>
				<div align="left" style="padding-left: 6rem;">注意</div>
				<div align="left" style="padding-left: 6rem;font-size: 0.875rem;">图像需近30天内</div>
				<div align="left" style="padding-left: 6rem;font-size: 0.875rem;">有效格式：.stl、.obj</div>
				<div align="left" style="padding-left: 6rem;font-size: 0.875rem;">最小文件大小：500KB</div>
				<div align="left" style="padding-left: 6rem;font-size: 0.875rem;">最大文件大小：11MB</div>
			</el-col>
			<el-col :span="12" style="text-align: left;padding-left: 30px;padding-top: 0.625rem;overflow-y: auto;height: 430px;">
				<!-- 请上传左侧 -->
				<div class="shanghe" v-if="sh">
					<div style="font-size: 0.875rem;">请上传左侧</div>
					<el-upload
					  class="upload-demo"
					  drag
					  action=''
					  ref="upload"
					  :http-request="changeFile"
					  multiple
					  style="height: 200px;"
					  >
					  <span class="zuo" style="display: inline-block;"></span>
					  <img src="../assets/kounei/kouneileft.svg" width="150" height="150" />
					  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					  <div class="el-upload__tip" slot="tip"></div>
					</el-upload>
					
				</div>
				<!-- 请上传右侧 -->
				<div class="xiahe"  v-if="xh">
				<div style="margin-top: 0.3125rem;font-size: 0.875rem;">请上传右侧</div>
				<el-upload
				  class="upload-demo1"
				  drag
				  action=''
				  multiple
				  :http-request="changeFile1"
				  >
				   <span class="you"></span><br />
				  <img src="../assets/kounei/kouneiright.svg" width="150" height="150" />
				  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
				  <div class="el-upload__tip" slot="tip"></div>
				</el-upload>
				</div>
				<!-- 正面 -->
				<div class="yaohe" v-if="yh">
				<div style="margin-top: 0.3125rem;font-size: 0.875rem;">请上传正面</div>
				<el-upload
				  class="upload-demo1"
				  drag
				  action=''
				  multiple
				  :http-request="changeFile2"
				  >
				  <span class="zheng"></span><br />
				    <img src="../assets/kounei/kouneizheng.svg" width="150" height="150" />
				  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
				  <div class="el-upload__tip" slot="tip"></div>
				</el-upload>
				</div>
				<!-- 上颌 -->
				<div class="yaohe" v-if="yh">
				<div style="margin-top: 0.3125rem;font-size: 0.875rem;">请上传上颌</div>
				<el-upload
				  class="upload-demo1"
				  drag
				  action=''
				  multiple
				  :http-request="changeFile3"
				  >
				  <span class="shang"></span><br />
				  <img src="../assets/kounei/kouneishang.svg" width="150" height="150" />
				  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
				  <div class="el-upload__tip" slot="tip"></div>
				</el-upload>
				</div>
				<!-- 下颌 -->
				<div class="yaohe" v-if="yh">
				<div style="margin-top: 0.3125rem;font-size: 0.875rem;">请上传下颌</div>
				<el-upload
				  class="upload-demo1"
				  drag
				  action=''
				  multiple
				  :http-request="changeFile4"
				  >
				  <span class="xia"></span><br />
				   <img src="../assets/kounei/kouneixia.svg" width="150" height="150" />
				  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
				  <div class="el-upload__tip" slot="tip"></div>
				</el-upload>
				</div>
				<button @click="goOrderFour" class="btnNextMianXiang" style="width: 30%;" type="button">返回</button>
				<button @click="nextStepKN" class="btnNextMianXiang" style="width: 30%;" type="button">下一步</button>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				
			</el-col>
		</el-row>
	</div>
</template>

<script>
	export default{
		data:function(){
			return{
				sh:true,
				xh:true,
				yh:true,
				ruleForm:{
					chengxiangDate:'',
					oid:'',
					jzType:'',
				},
				
				jzTypes:[
					{
					          value: '不带颌垫的上颌矫治器',
					          label: '不带颌垫的上颌矫治器'
					        }, {
					          value: '不带颌垫的下颌矫治器',
					          label: '不带颌垫的下颌矫治器'
					        }, {
					          value: '带颌垫的矫治器',
					          label: '带颌垫的矫治器'
					        }
				]
			}
		},
		methods:{
			//选择矫治器类型
			selectJZType(value){
				console.log(value);
				if(value=="不带颌垫的上颌矫治器"){//不带颌垫的上颌矫治器
					this.xh=false;
					this.yh=false;
					this.sh=true;
				}else if(value=="不带颌垫的下颌矫治器"){//不带颌垫的下颌矫治器
					this.xh=true;
					this.yh=false;
					this.sh=false;
				}else if(value=="带颌垫的矫治器"){//带颌垫的矫治器
					this.xh=true;
					this.yh=true;
					this.sh=true;
				}
			},
			// beforeAvatarUpload(file) {
			//     console.log('file', file)
			//     let types = ['image/jpeg', 'image/jpg', 'image/png'];
			//     const isImage = types.includes(file.type);
			//     if (!isImage) {
			//         this.$message.error('上传图片只能是 .jpg、.jpeg、.dcm(DICOM)、.bmp、.tif、.tiff 格式!');
			//       }else{
			//         //this.uploadToken.key=`upload_pic_${Date.parse(new Date())}${file.name}`
			//       }
			// 	  const isLtSize = file.size / 1024 / 1024 < 5;
			// 	   if (!isLtSize) {
			// 	            this.$message.error('上传图片大小不能超过 1MB!');
			// 	       }
			//     },
				
				//上传左侧
			changeFile(file){
				console.log('file', file)
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'knZuo')
					  console.log(formData.get('type'));
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
				   		console.log(res.data);
						if(res.data.code==200){
							this.$message.success("上传成功");
						}
				   		//document.getElementById("i").setAttribute("src",res.data.data.picUrl);
				   	}).catch(err=>{
				   		console.log(err);
				   	});
			},
			//上传右侧
			changeFile1(file){
				console.log('file', file)
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'knYou')
					  console.log(formData.get('type'));
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
				   		console.log(res.data);
						if(res.data.code==200){
							this.$message.success("上传成功");
						}
				   		//document.getElementById("i").setAttribute("src",res.data.data.picUrl);
				   	}).catch(err=>{
				   		console.log(err);
				   	});
			},
			//上传下颌
			changeFile2(file){
				console.log('file', file)
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'knZheng')
					  console.log(formData.get('type'));
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
				   		console.log(res.data);
						if(res.data.code==200){
							this.$message.success("上传成功");
						}
				   		//document.getElementById("i").setAttribute("src",res.data.data.picUrl);
				   	}).catch(err=>{
				   		console.log(err);
				   	});
			},
			changeFile3(file){
				console.log('file', file)
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'knShang')
					  console.log(formData.get('type'));
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
				   		console.log(res.data);
						if(res.data.code==200){
							this.$message.success("上传成功");
						}
				   		//document.getElementById("i").setAttribute("src",res.data.data.picUrl);
				   	}).catch(err=>{
				   		console.log(err);
				   	});
			},
			changeFile4(file){
				console.log('file', file)
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'knXia')
					  console.log(formData.get('type'));
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
				   		console.log(res.data);
						if(res.data.code==200){
							this.$message.success("上传成功");
						}
				   		//document.getElementById("i").setAttribute("src",res.data.data.picUrl);
				   	}).catch(err=>{
				   		console.log(err);
				   	});
			},
			//下一步
			nextStepKN(){
				//上传日期
				 let formData1 = new FormData()
				formData1.append('date',this.ruleForm.chengxiangDate)// 传文件
				formData1.append('oid', this.ruleForm.oid)
				formData1.append('type',"knDate")
			
				this.$axios.post("client/order/uploadResourceDate",formData1,{
					headers:{
						"Content-type":"application/json"
					}
				}).then(res=>{
					console.log(res);
					if(res.data.code==200){
						this.$message({
							message:"添加成像日期成功",
							type:"success",
							duration:900
						});
						this.$router.push("/addorder/ordersix");
					}
				}).catch(err=>{
					this.$message({
						message:"添加矫治器类型失败",
						type:"error",
						duration:900
					});
				});
				
			},
			//返回
			goOrderFour(){
				this.$router.push("/addorder/orderfour")
			}
		},
		mounted:function(){
			this.$store.commit('setStepIndex', 4);
			//设置默认地址
			sessionStorage.setItem("location","orderfive")
			//获取当前添加的订单oid
			//获取oid
			let oid=sessionStorage.getItem("oid");
			console.log(oid);
			if(oid==null){
				this.$alert("请填写患者信息","提示");
				this.$router.push("/addorder/orderone");
			}else{
				this.ruleForm.oid=oid;
			}
		},
		created:function(){
			//如果是编辑订单要获取oid的值
			let oid=sessionStorage.getItem("oid");
		
			if(oid!="undefined"&&oid!=''&&oid!=null){
				this.ruleForm.oid=oid;
				this.$axios.get("/client/order/getOrderInfo",{
					params:{
						oid:this.ruleForm.oid
					}
				}).then(res=>{
					console.log(res.data.data.resource);
					let knDate=res.data.data.resource.knDate;//口内日期
					let zuo=res.data.data.resource.knZuo;//左
					let you=res.data.data.resource.knYou;//右
					let zheng=res.data.data.resource.knZheng;//正
					let shang=res.data.data.resource.knShang;//上
					let xia=res.data.data.resource.knXia;//下
					//日期回显
					if(knDate!=null){
						this.ruleForm.chengxiangDate=knDate;
					}
					//回显左
					if(zuo!=null){
						document.getElementsByClassName("zuo")[0].innerHTML="口内左侧已上传"
						document.getElementsByClassName("zuo")[0].style.color="#2B9BB3";
						document.getElementsByClassName("zuo")[0].style.fontWeight="bolder";
					}
					//回显右
					if(you!=null){
						document.getElementsByClassName("you")[0].innerHTML="口内右侧已上传"
						document.getElementsByClassName("you")[0].style.color="#2B9BB3";
						document.getElementsByClassName("you")[0].style.fontWeight="bolder";
					}
					//回显正
					if(zheng!=null){
						document.getElementsByClassName("zheng")[0].innerHTML="口内正面已上传"
						document.getElementsByClassName("zheng")[0].style.color="#2B9BB3";
						document.getElementsByClassName("zheng")[0].style.fontWeight="bolder";
					}
					//回显下
					if(shang!=null){
						document.getElementsByClassName("shang")[0].innerHTML="口内上颌已上传"
						document.getElementsByClassName("shang")[0].style.color="#2B9BB3";
						document.getElementsByClassName("shang")[0].style.fontWeight="bolder";
					}
					//回显左
					if(xia!=null){
						document.getElementsByClassName("xia")[0].innerHTML="口内下颌已上传"
						document.getElementsByClassName("xia")[0].style.color="#2B9BB3";
						document.getElementsByClassName("xia")[0].style.fontWeight="bolder";
					}
				}).catch(err=>{
					console.log(err);
				});
			}
	},
	}
</script>

<style scoped="scoped">
	.kounei{
		background-image: linear-gradient(to bottom,white, #9BEDEB, #28D9D5);
		height: 100%;
	}
	.btnNextMianXiang{
		height: 1.875rem;
		border-radius: 0.625rem;
		background-image: linear-gradient(to right, white, #28D9D5, #2B9BB3);
		border: none;
		margin-bottom: 1.25rem;
	}
	.xy{
		display: none;
	}
</style>
